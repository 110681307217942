import React, { useEffect, useRef, useState } from "react";
import { Input } from "antd";
import BasicLayout from "../../Layout/BasicLayout";
import { useDispatch, useSelector } from "react-redux";
import { resetChatData } from "../../Redux/Features/Chat/addChatSlice";
import { useNavigate, useParams } from "react-router-dom";
import { resetProblemData } from "../../Redux/Features/Problem/getProblemSlice";
import { getCookie } from "../../utils/helper";
import setAuthToken from "../../utils/axios/setAuthToken";
import EditorModal from "../../components/Modals/editorModal";
import MergeDocumentModal from "../../components/Modals/mergeDocumentModal";
import ChatList from "../../components/chatList";
import scrollToBottom from "../../utils/problemConversation/Chat/scrollToBottom";
import getThread from "../../utils/problemConversation/Thread/getThread";
import updateChatHelper from "../../utils/problemConversation/Chat/updateChatHelper";
import aiCheckerAndHumanChecker from "../../utils/problemConversation/AIAndHuman/aiAndHumanChecker";
import handleMessage from "../../utils/problemConversation/Chat/handleMessage";
import addProblemDataUtility from "../../utils/problemConversation/Problem/addProblemData";
import compileChats from "../../utils/problemConversation/Actions/CompileChats";
import { cancelCompileToDocument } from "../../Redux/Features/Document/compileDocumentSlice";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
const ProblemConversation = () => {
  const { id: problemId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: problemData } = useSelector((state) => state?.getProblem);
  const { data: addChatData } = useSelector((state) => state?.addChat);
  const { data: aiEvalResponse } = useSelector((state) => state.AIEvaluation);
  const { data: aiRequeryResponse } = useSelector((state) => state.AIRequery);
  const [isResume, setIsResume] = useState(false);
  const [isChatCompleted, setIsChatCompleted] = useState(false);
  const [isWritting, setIsWritting] = useState(true);
  const [isLoadingChats, setIsLoadingChats] = useState(true);
  const [chats, setChats] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [isChat, setIsChat] = useState(false);
  const [message, setMessage] = useState("");
  const [editedResponse, setEditedResponse] = useState("");
  const [compiledChats, setCompiledChats] = useState("");
  const [isCompiling, setIsCompiling] = useState(false);
  const [isEditorModalVisible, setIsEditorModalVisible] = useState(false);
  const [isMergeDocumentModalVisible, setIsMergeDocumentModalVisible] =
    useState(false);

  const containerRef = useRef(null);
  const [isMdOrAbove, setIsMdOrAbove] = useState(window.innerWidth >= 768);
  const [isSMOrBellow, setIsSMOrBellow] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMdOrAbove(window.innerWidth >= 768);
      setIsSMOrBellow(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    const resetState = () => {
      setChats([]);
      setIsTyping(false);
      setIsChatCompleted(false);
      setIsWritting(true);
      setIsChat(false);
      setIsResume(false);
      setMessage("");
      dispatch(resetProblemData());
      dispatch(resetChatData());
    };
    resetState();
    return resetState;
  }, []);

  useEffect(() => {
    if (problemId) {
      addProblemDataUtility(
        dispatch,
        setIsResume,
        setChats,
        setIsChatCompleted,
        chats,
        setMessage,
        setIsChat,
        setIsTyping,
        navigate,
        problemData,
        problemId,
        message
      );
    }
  }, [problemId]);

  useEffect(() => {
    const token = getCookie("token");
    setAuthToken(token);
    if (isResume) {
      getThread(problemId, setChats, setIsLoadingChats);
    }
  }, [isResume]);

  useEffect(() => {
    scrollToBottom(containerRef);
  }, [chats.length]);

  useEffect(() => {
    if (!isResume && addChatData && Object.keys(addChatData).length > 0) {
      setChats((prev) => [
        ...prev,
        {
          role: "assistant",
          isMoreButtons: true,
          humanChecker: false,
          isAIChecking: true,
          data: addChatData,
        },
      ]);
    }
  }, [addChatData]);

  useEffect(() => {
    aiCheckerAndHumanChecker(
      chats,
      isResume,
      setChats,
      aiEvalResponse,
      aiRequeryResponse,
      dispatch,
      problemId
    );
  }, [chats.length]);

  const handleUpdateResponse = () => {
    updateChatHelper(editedResponse, setChats);
    setIsEditorModalVisible(false);
  };

  const handleCancelEditorModal = () => {
    dispatch(cancelCompileToDocument());
    setIsEditorModalVisible(false);
    setIsCompiling(false);
    setCompiledChats("");
    setEditedResponse("");
  };
  const handleEnterPress = (e) => {
    const stepKey = chats[chats.length - 1]?.data?.step?.key || "";
    if (e.key === "Enter") {
      let humanCheckerAnswer = "";
      if (stepKey) {
        humanCheckerAnswer = chats[chats.length - 1]?.data[stepKey]?.content;
      }
      handleMessage(
        message,
        setChats,
        chats,
        setMessage,
        setIsChat,
        dispatch,
        setIsTyping,
        navigate,
        problemData,
        problemId,
        message,
        humanCheckerAnswer
      );
      setMessage("");
    }
  };
  const handleCompileChats = () => {
    compileChats(
      chats,
      setCompiledChats,
      setIsCompiling,
      setIsEditorModalVisible
    );
  };

  // HANDLE DOWNLOAD AS MARKDOWN
  const handleDownloadMarkdown = () => {
    let markdownText = "";
    for (let chat of chats) {
      const dataKeys = Object.keys(chat.data || {});

      // Loop through each key in chat data
      for (let key of dataKeys) {
        if (chat.data[key]?.content) {
          markdownText += chat.data[key].content + "\n";
        }
      }
    }
    const blob = new Blob([markdownText], { type: "text/markdown" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    const date = new Date().toISOString();
    a.download = `${date}-markdown.md`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <BasicLayout
      isPadding={false}
      setIsMergeDocumentModalVisible={setIsMergeDocumentModalVisible}
      problemData={problemData}
      handleCompileChats={handleCompileChats}
      handleDownloadMarkdown={handleDownloadMarkdown}
    >
      <div
        className="px-6 bg-[#fff] flex flex-col justify-between m-auto relative"
        ref={containerRef}
      >
        {isLoadingChats && isResume ? (
          <div>
            <Skeleton height={100} />
            <Skeleton count={3} />
          </div>
        ) : (
          <ChatList
            chats={chats}
            isMdOrAbove={isMdOrAbove}
            isSMOrBellow={isSMOrBellow}
            isTyping={isTyping}
            isResume={isResume}
            isChatCompleted={isChatCompleted}
            isWritting={isWritting}
            setIsWritting={setIsWritting}
            setChats={setChats}
            editedResponse={editedResponse}
            setEditedResponse={setEditedResponse}
            setIsEditorModalVisible={setIsEditorModalVisible}
            setIsChat={setIsChat}
            setIsTyping={setIsTyping}
            setMessage={setMessage}
            problemData={problemData}
            problemId={problemId}
            message={message}
            setIsResume={setIsResume}
          />
        )}
        <div className="z-10 bg-white p-1 sticky bottom-0">
          <Input.TextArea
            rows={3}
            size="large"
            value={message}
            placeholder="Type a message here and hit Enter..."
            onChange={(e) => setMessage(e?.target?.value)}
            onPressEnter={handleEnterPress}
            disabled={!isChat}
          />
        </div>
      </div>

      <EditorModal
        isEditorModalVisible={isEditorModalVisible}
        handleUpdateResponse={handleUpdateResponse}
        handleCancelEditorModal={handleCancelEditorModal}
        editedResponse={editedResponse}
        setEditedResponse={setEditedResponse}
        isCompiling={isCompiling}
        compiledChats={compiledChats}
        setCompiledChats={setCompiledChats}
        chats={chats}
      />
      <MergeDocumentModal
        chats={chats}
        isMergeDocumentModalVisible={isMergeDocumentModalVisible}
        setIsMergeDocumentModalVisible={setIsMergeDocumentModalVisible}
      />
    </BasicLayout>
  );
};

export default ProblemConversation;
