import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import validator from "validator";
import { toastSuccess } from "../../utils/toast";
import BasicLayout from "../../Layout/BasicLayout";
import { useDispatch, useSelector } from "react-redux";
import { updateProblem } from "../../Redux/Features/Problem/updateProblemSlice";
import { getProblem } from "../../Redux/Features/Problem/getProblemSlice";
import { Button } from "../../components/ui/button";
import { Input, Select, Spin } from "antd";
import { Label } from "../../components/ui/label";
import { supportedLanguages } from "../../Constants";
const { Option } = Select;
const { TextArea } = Input;
const Problem = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading: isProblemLoading } = useSelector(
    (state) => state?.getProblem
  );
  const { isLoading: updateProblemLoading } = useSelector(
    (state) => state?.updateProblem
  );
  const [problem, setProblem] = useState({
    title: "",
    description: "",
    language: "",
    library: { name: "", visibility: "" },
    stepCount: 0,
    maxCount: 8,
    variables: "",
    styleInformation: "",
    demoProblem: "",
  });
  const [isOnEditMode, setIsOnEditMode] = useState(false);
  let [clientErrors, setClientErrors] = useState({});

  useEffect(() => {
    dispatch(getProblem({ id }));
  }, [id]);

  useEffect(() => {
    setProblem({
      title: data?.title,
      description: data?.description,
      language: data?.language,
      library: data?.library,
      variables: data?.variables,
      styleInformation: data?.styleInformation,
      demoProblem: data?.demoProblem,
    });
  }, [data]);

  const handleUpdateProblem = async () => {
    let isErr = false;
    let errors = {};

    if (validator.isEmpty(problem?.title)) {
      isErr = true;
      errors.title = "Title is required";
    }
    if (validator.isEmpty(problem?.description)) {
      isErr = true;
      errors.description = "Description is required";
    }

    if (isErr) {
      isErr = false;
      setClientErrors(errors);
    } else {
      isErr = false;
      setClientErrors({});
      dispatch(
        updateProblem({
          data: problem,
          id,
          onSuccess: (data) => {
            dispatch(getProblem({ id }));
            toastSuccess(data);
            setIsOnEditMode(false);
          },
        })
      );
    }
  };

  return (
    <BasicLayout>
      {isProblemLoading ? (
        <div className="flex justify-center items-center h-screen">
          <Spin size="large" />
        </div>
      ) : (
        <div className="flex justify-center px-6 mt-4">
          <div className="bg-gray-100 p-6 shadow-lg rounded-xl w-full max-w-3xl">
            {isOnEditMode ? (
              <div className="flex flex-col gap-6">
                <h2 className="text-xl font-semibold mb-4">
                  Update Your Problem
                </h2>
                <div className="flex flex-col gap-4">
                  <Label>Problem Title</Label>
                  <input
                    type="text"
                    className="w-full p-2 border rounded-md mb-2"
                    placeholder="Problem Title"
                    value={problem?.title}
                    onChange={(e) =>
                      setProblem({ ...problem, title: e?.target?.value })
                    }
                  />
                  {clientErrors?.title && (
                    <span className="text-red-500">{clientErrors?.title}</span>
                  )}
                </div>
                <div className="flex flex-col gap-4">
                  <Label>Problem Language</Label>
                  <Select
                    size="large"
                    placeholder="Select language"
                    value={problem?.language || ""}
                    onChange={(value) =>
                      setProblem({ ...problem, language: value })
                    }
                  >
                    {supportedLanguages?.map((language) => (
                      <Option key={language} value={language}>
                        {language}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className="flex flex-col gap-4">
                  <Label>Problem Description</Label>
                  <TextArea
                    className="w-full p-2 border rounded-md mb-2"
                    placeholder="Problem Description"
                    value={problem?.description}
                    rows={12}
                    onChange={(e) =>
                      setProblem({ ...problem, description: e?.target?.value })
                    }
                  />
                  {clientErrors?.description && (
                    <span className="text-red-500">
                      {clientErrors?.description}
                    </span>
                  )}
                </div>
                <div className="flex items-center gap-4">
                  <Button
                    className="w-full mt-4 text-lg"
                    onClick={handleUpdateProblem}
                    disabled={updateProblemLoading}
                  >
                    Update Problem
                  </Button>
                  <Button
                    className="mt-4 bg-gray-200 text-black text-lg hover:bg-red-400"
                    disabled={updateProblemLoading}
                    onClick={() => setIsOnEditMode(false)}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            ) : (
              <div className="space-y-6">
                <section className="p-4 bg-white shadow rounded-md">
                  <h2 className="text-lg font-semibold">Problem Title</h2>
                  <p>{problem?.title}</p>
                </section>
                <section className="p-4 bg-white shadow rounded-md">
                  <h2 className="text-lg font-semibold">Problem Language</h2>
                  <p>{problem?.language}</p>
                </section>

                <section className="p-4 bg-white shadow rounded-md">
                  <h2 className="text-lg font-semibold">Problem Description</h2>
                  <p>{problem?.description}</p>
                </section>

                <section className="p-4 bg-white shadow rounded-md">
                  <h2 className="text-lg font-semibold">Library</h2>
                  <p>
                    <strong>Name:</strong> {problem?.library?.name}
                  </p>
                  <p>
                    <strong>Visibility:</strong> {problem?.library?.visibility}
                  </p>
                </section>
                {problem?.demoProblem && (
                  <section className="p-4 bg-white shadow rounded-md">
                    <h2 className="text-lg font-semibold">Demo Problem</h2>
                    <p>{problem?.demoProblem}</p>
                  </section>
                )}
                {problem?.variables && (
                  <section className="p-4 bg-white shadow rounded-md">
                    <h2 className="text-lg font-semibold">Variables</h2>
                    <p>{problem?.variables}</p>
                  </section>
                )}
                {problem?.styleInformation && (
                  <section className="p-4 bg-white shadow rounded-md">
                    <h2 className="text-lg font-semibold">Style Information</h2>
                    <p>{problem?.styleInformation}</p>
                  </section>
                )}
                <div className="flex items-center gap-4">
                  <Button
                    className="w-full mt-4 text-lg"
                    onClick={() => setIsOnEditMode(true)}
                  >
                    Edit
                  </Button>
                  <Button
                    className="mt-4 text-lg bg-gray-200 text-black  hover:bg-gray-100"
                    onClick={() => navigate("/home")}
                  >
                    Go Home
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </BasicLayout>
  );
};

export default Problem;
