import React, { Fragment, useEffect } from "react";
import { Route, useNavigate, Routes, useLocation } from "react-router-dom";
import Home from "../Pages/Home/Home";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { getUser } from "../Redux/Features/User/getUserSlice";
import { getCookie, setCookie } from "../utils/helper";
import ProblemConversation from "../Pages/ProblemConversation/ProblemConversation";
import Threads from "../Pages/Threads/Threads";
import About from "../Pages/About/About";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import DisplayUsers from "../Pages/Users/Users";
import Promptlets from "../Pages/Promptlets/Promptlets";
import AddminOnlyThreads from "../Pages/Threads/AdminOnlyThreads";
import Help from "../Pages/Help/Help";
import SignIn from "../Pages/Register/Signin";
import Signup from "../Pages/Register/Signup";
import Problem from "../Pages/Problem/Problem";

function useQuery(key) {
  const { search } = useLocation();
  return new URLSearchParams(search).get(key);
}
const AppRoutes = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryToken = useQuery("token");

  useEffect(() => {
    const initializeApp = async () => {
      if (queryToken) {
        setCookie("token", queryToken);
        sessionStorage.setItem("tokenExpiration", Date.now() + 3600 * 1000);
        dispatch(getUser(queryToken));
        navigate("/home");
      } else {
        const token = getCookie("token");
        const tokenExpiration = sessionStorage.getItem("tokenExpiration");
        if (tokenExpiration && Date.now() > tokenExpiration) {
          setCookie("token", "", -1);
          sessionStorage.removeItem("tokenExpiration");
          navigate("/");
          return;
        }
        if (!token) {
          navigate("/");
        } else {
          dispatch(getUser(token));
          navigate("/home");
        }
      }
    };

    initializeApp();
  }, [queryToken]);

  return (
    <Fragment>
      <ToastContainer />
      <Routes>
        <Route path="/home" element={<PrivateRoute element={<Home />} />} />
        <Route
          path="/threads"
          element={<PrivateRoute element={<Threads />} />}
        />
        <Route path="/admin-only-threads" element={<AddminOnlyThreads />} />
        <Route
          path="/promptlets"
          element={<PrivateRoute element={<Promptlets />} />}
        />
        <Route
          path="/users"
          element={<PrivateRoute element={<DisplayUsers />} />}
        />
        <Route path="/about" element={<PrivateRoute element={<About />} />} />
        <Route path="/help" element={<PrivateRoute element={<Help />} />} />

        <Route
          path="/chat/:id"
          element={<PrivateRoute element={<ProblemConversation />} />}
        />
        <Route path="/problem/:id" element={<PrivateRoute element={<Problem/>}/>}/>
        <Route path="/" element={<PublicRoute element={<SignIn />} />} />
        <Route path="/sign-up" element={<PublicRoute element={<Signup />} />} />
      </Routes>
    </Fragment>
  );
};

export default AppRoutes;
