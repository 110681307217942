import { Button } from "antd";
import React from "react";

const MergeCompileDocument = ({setIsMergeDocumentModalVisible,handleCompileChats,handleDownloadMarkdown}) => {
  return (
    <div className="flex sticky p-4 gap-2 mt-2">
      <Button
        type="dashed"
        onClick={handleCompileChats}
        className="!bg-gradient-to-r !from-blue-500 !to-blue-600 !text-white hover:!from-blue-600 hover:!to-blue-700 text-md font-bold"
      >
        Compile to Document
      </Button>
      <Button
        type="dashed"
        onClick={() => setIsMergeDocumentModalVisible(true)}
        className="!bg-gradient-to-r !from-blue-500 !to-blue-600 !text-white hover:!from-blue-600 hover:!to-blue-700 text-md font-bold"
      >
        Merge Document
      </Button>
      <Button
        type="dashed"
        onClick={handleDownloadMarkdown}
        className="!bg-gradient-to-r !from-blue-500 !to-blue-600 !text-white hover:!from-blue-600 hover:!to-blue-700 text-md font-bold"
      >
      Download As Markdown
      </Button>
    </div>
  );
};

export default MergeCompileDocument;
