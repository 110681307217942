import axios from "axios";
import { axiosErrorHandler, getCookie } from "../../../utils/helper";
import setAuthToken from "../../../utils/axios/setAuthToken";
import { BASE_URL } from "../../../Config";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


export const getAllUsers = createAsyncThunk(
  "Users/Get-All-Users",
  async (_, { rejectWithValue }) => {
    try {
      const token = getCookie("token");
      setAuthToken(token);
      const response = await axios.get(`${BASE_URL}/api/users`);
      return response?.data;
    } catch (error) {
      axiosErrorHandler(error);
      rejectWithValue(error?.response?.data);
    }
  }
);
const initialState = {
  isLoading: false,
  isError: null,
  errors: [],
  isSuccess: false,
  message: "",
  data: [],
};
const getAllUsersSlice = createSlice({
  name: "Get All Users",
  initialState: initialState,
  reducers: {deleteUserData: (state, action) => {
    state.data = state.data.filter(
      (user) => user._id !== action.payload
    );
  }},
  extraReducers: (builder) => {
    builder.addCase(getAllUsers.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(getAllUsers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.errors = [];
      state.isSuccess = action?.payload?.data?.isSuccess;
      state.message = action?.payload?.data?.message;
      state.data = action?.payload?.data?.data;
    });
    builder.addCase(getAllUsers.rejected, (state, action) => {
      state.isLoading = false;
      state.errors = action.payload?.data?.errors || [];
      state.products = [];
      state.isError = true;
    });
  },
});
export const {deleteUserData} = getAllUsersSlice?.actions;
export default getAllUsersSlice?.reducer;
