import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosErrorHandler, getCookie } from "../../../utils/helper";
import setAuthToken from "../../../utils/axios/setAuthToken";
import axios from "axios";
import { BASE_URL } from "../../../Config";

export const getAllLogsOfStepInSpecificConversation = createAsyncThunk(
  "Get Step Logs ",
  async ({ stepId }, { rejectWithValue }) => {
    try {
      const token = getCookie("token");
      setAuthToken(token);
      const conversationId = localStorage.getItem("conversationId");
      const response = await axios.get(
        `${BASE_URL}/api/user/logs/currentlogs?stepId=${stepId}&&conversationId=${conversationId}`
      );
      return response?.data;
    } catch (error) {
      axiosErrorHandler(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);
const initialState = {
  isLoading: false,
  isSuccess: false,
  message: "",
  data: [],
};
const getAllLogsOfStepInSpecificConversationSlice = createSlice({
  name: "Get Step Logs  ",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllLogsOfStepInSpecificConversation.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(
      getAllLogsOfStepInSpecificConversation.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action?.payload?.data?.message;
        state.data = action?.payload?.data?.data;
      }
    );
    builder.addCase(
      getAllLogsOfStepInSpecificConversation.rejected,
      (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action?.payload?.data?.message;
      }
    );
  },
});

export default getAllLogsOfStepInSpecificConversationSlice?.reducer;
