import React, { useEffect, useState } from "react";
import BasicLayout from "../../Layout/BasicLayout";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table } from "antd";
import { getAllProblems } from "../../Redux/Features/Problem/getAllProblemsSlice";
import { getLibrary } from "../../Redux/Features/Promptlet/getLibrarySlice";
import columns from "./Columns";
import EditProblemModal from "../../components/Problems/EditProblemModal";
import AddProblemModal from "../../components/Problems/addProblemModal";
import DataSourceComponent from "./DataSourceComponent";

const Home = () => {
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [addProblemModal, setAddProblemModal] = useState({
    open: false,
    title: "",
    description: "",
    library: "",
    language: "",
  });
  
  const [editProblemModal, setEditProblemModal] = useState({
    open: false,
    id: "",
    title: "",
    description: "",
    library: "",
    language: "",
    variables: "",
    demoProblem: "",
    styleInformation: "",
  });

  const { data: promptlets } = useSelector((state) => state?.getLibrary);

  let { data: problemsData, isLoading: getAllProblemsLoading } = useSelector(
    (state) => state?.getAllProblems
  );
  const { isLoading: addProblemLoading } = useSelector(
    (state) => state?.addProblem
  );
  const { isLoading: updateProblemLoading } = useSelector(
    (state) => state?.updateProblem
  );

  let [clientErrors, setClientErrors] = useState({});

  useEffect(() => {
    dispatch(getAllProblems());
    dispatch(getLibrary());
  }, []);
  const handleTableChange = (newPagination) => {
    setPagination(newPagination);
  };
  return (
    <BasicLayout>
      <div className="mt-10">
        <div className="flex justify-end me-4">
          <Button
            type="primary"
            onClick={() => setAddProblemModal((prev)=>({...prev,open:true}))}
            className="!bg-gradient-to-r !from-blue-500 !to-blue-600 !text-white hover:!from-blue-600 hover:!to-blue-700 text-md font-bold"
          >
            Add Problem
          </Button>
        </div>
        <div className="mx-4 mt-4 rounded-lg shadow-sm">
          <Table
            bordered
            loading={getAllProblemsLoading}
            dataSource={DataSourceComponent(
              problemsData,
              promptlets,
              setEditProblemModal
            )}
            columns={columns}
            pagination={{
              current: pagination.current,
              pageSize: pagination.pageSize,
              showSizeChanger: true,
              className: "p-2 rounded-lg",
              onChange: (page, pageSize) =>
                setPagination({ ...pagination, current: page, pageSize }),
              onShowSizeChange: (current, size) =>
                setPagination({ ...pagination, pageSize: size }),
            }}
            onChange={handleTableChange}
          />
        </div>
      </div>
      <AddProblemModal
        addProblemModal={addProblemModal}
        setAddProblemModal={setAddProblemModal}
        setEditProblemModal={setEditProblemModal}
        addProblemLoading={addProblemLoading}
        promptlets={promptlets}
        clientErrors={clientErrors}
        setClientErrors={setClientErrors}
      />

      <EditProblemModal
        editProblemModal={editProblemModal}
        setEditProblemModal={setEditProblemModal}
        updateProblemLoading={updateProblemLoading}
        promptlets={promptlets}
        clientErrors={clientErrors}
        setClientErrors={setClientErrors}
      />
    </BasicLayout>
  );
};

export default Home;
