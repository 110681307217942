import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../../Config";
import axios from "axios";
import { axiosErrorHandler } from "../../../utils/helper";

const initialState = {
  isLoading: false,
  isError: null,
  errors: [],
  isSuccess: false,
  message: "",
  promptlet: {},
};

export const getPromptlet = createAsyncThunk(
  "Library/Get-Library",
  async ({id,onSuccess}, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/api/user/library/${id}`);
      onSuccess&&onSuccess(response?.data?.data?.data)
      return response?.data;
    } catch (error) {
      axiosErrorHandler(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

const getPromptletSlice = createSlice({
  name: "Get promptlet",
  initialState: initialState,
  reducers: {
    resetPromptlet: (state) => {
      state.promptlet = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPromptlet.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(getPromptlet.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.errors = [];
      state.isSuccess = action?.payload?.data?.isSuccess;
      state.message = action?.payload?.data?.message;
      state.promptlet = action?.payload?.data?.data;
    });
    builder.addCase(getPromptlet.rejected, (state, action) => {
      state.isLoading = false;
      state.errors = action.payload?.data?.errors || [];
      state.isError = true;
    });
  },
});

export const { resetPromptlet,deletePromptletData } = getPromptletSlice?.actions;

export default getPromptletSlice?.reducer;
