import validator from "validator";
import { addProblem } from "../../Redux/Features/Problem/addProblemSlice";
import { getAllProblems } from "../../Redux/Features/Problem/getAllProblemsSlice";

const saveAsNewProblem = async (
  addProblemModal,
  setAddProblemModal,
  setEditProblemModal,
  setClientErrors,
  dispatch
) => {
  let isErr = false;
  let errors = {};

  if (validator.isEmpty(addProblemModal?.title)) {
    isErr = true;
    errors.title = "Title is required";
  }
  if (validator.isEmpty(addProblemModal?.description)) {
    isErr = true;
    errors.description = "Description is required";
  }

  if (addProblemModal?.steps?.length === 0) {
    isErr = true;
    errors.steps = "Steps is required";
  }

  if (isErr) {
    isErr = false;
    setClientErrors(errors);
  } else {
    isErr = false;
    setClientErrors({});
    dispatch(
      addProblem({
        data: addProblemModal,
        onSuccess: (problem) => {
          dispatch(getAllProblems());
          setEditProblemModal({
            open: true,
            id: problem?._id,
            title: problem?.title,
            description: problem?.description,
            library: problem?.library,
            language: problem?.language,
            variables: problem?.variables,
            demoProblem: problem?.demoProblem,
            styleInformation: problem?.styleInformation,
          });
          setAddProblemModal({
            open: false,
            title: "",
            description: "",
            library: "",
            language: "",
          });
        },
      })
    );
  }
};

export default saveAsNewProblem;
