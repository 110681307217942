import React, { useCallback, useState } from "react";
import { Input, Modal, Select, Form, Tabs } from "antd";
import handleStepsData from "../../utils/home/handleStepsData";
import saveAsNewProblem from "../../utils/home/saveAsNewProblem";
import { supportedLanguages } from "../../Constants/index";
import { useDropzone } from "react-dropzone";
import { FaFileAlt, FaFilePdf, FaFileUpload, FaTimes } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { Button } from "../ui/button";
import pdfToText from "react-pdftotext";

const { TextArea } = Input;
const { Option, OptGroup } = Select;

const AddProblemModal = ({
  addProblemModal,
  setAddProblemModal,
  setEditProblemModal,
  addProblemLoading,
  promptlets,
  clientErrors,
  setClientErrors,
}) => {

  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const [uploadedFile, setUploadedFile] = useState(null);
  const [selectedPromptlet,setSelectedPromptlet] = useState([]);

  // HANDLE CANCEL
  const handleCancel = () => {
    setAddProblemModal({
      open: false,
      title: "",
      description: "",
      library: "",
      language: "",
    });
    setUploadedFile(null);
    setAddProblemModal(false);
    setClientErrors({});
  };

  // HANDLE SAVE PROBLEM
  const handleSave = () => {
    saveAsNewProblem(
      addProblemModal,
      setAddProblemModal,
      setEditProblemModal,
      setClientErrors,
      dispatch
    );
  };

  // HANDLE STEP DATA
  const stepsData = handleStepsData(null, promptlets);

  // HANDLE PROMPTLET SELECTION
  const handlePromptletSelection = (value) => {
    const newSelectedPromptlet = promptlets?.filter(
      (promptlets) => promptlets?._id === value
    );
    setSelectedPromptlet(newSelectedPromptlet);
    setAddProblemModal((addProblemModal)=>({
      ...addProblemModal,
      library: value,
      title: newSelectedPromptlet[0]?.name,
    }));
  };

  // EXTRACT TEXT FROM TEXT FILE UPLOADED
  const extractTextFromTextFile = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      setAddProblemModal((addProblemModal)=>({...addProblemModal, description:e.target.result}));
    };
    reader.readAsText(file);
  };

  // EXTRACT TEXT FROM PDF FILE UPLOADED
  const extractTextFromPDFFile = async (file) => {
    if (!file) return;

    try {
        const text = await pdfToText(file);
        setAddProblemModal((addProblemModal)=>({...addProblemModal, description:text}));

    } catch (error) {
        console.error("Failed to extract text from PDF", error);
    }
};

  // SET ATTACHMENT MAX SIZE
  const maxSize = 2 * 1024 * 1024;

  // ON DRIP CALLBACK
  const onDrop = useCallback((acceptedFiles) => {
    // ALLOW ONLY PDF AND TEXT
    const filteredFiles = acceptedFiles.filter(
      (file) => (file.type === "text/plain" || file.type ==="application/pdf") && maxSize
    );

    if (filteredFiles.length > 0) {
      setError("");
      const filteredFile = filteredFiles[0];
      setUploadedFile(filteredFile);
      if(filteredFile.type ==="text/plain"){

        extractTextFromTextFile(filteredFile);
      }else{
        extractTextFromPDFFile(filteredFile);
      }
    } else {
      setError("No valid files accepted. Please upload PDF or text files.");
    }
  }, []);

  // USE DROP ZONE HOOK
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "application/pdf":[],
      "text/plain": [],
    },
    maxSize: maxSize,
  });

  // REMOVE FILE
  const removeFile = () => {
    setUploadedFile(null);
  };

  // HANDLE USE DEMO
  const handleUseDemo = ()=>{
    setAddProblemModal((addProblemModal)=>({
      ...addProblemModal,
      description: selectedPromptlet[0]?.demoProblem,
    }))
  }
  // TABS
  const tabs = ["Problem Description", "Attachments"];

  // TAB CHILDREN
  const tabChildren = [
    <div className="flex flex-col ">
       <div>
      <Button
        className="!bg-gradient-to-r !from-blue-500 !to-blue-600 hover:!from-blue-600 hover:!to-blue-700 !text-white font-bold text-md"
        onClick={() => handleUseDemo()}
      >
        Use Demo
      </Button>
    </div>,
      <Form.Item
        validateStatus={clientErrors?.description ? "error" : ""}
        help={clientErrors?.description}
        className="text-md font-bold"
      >
        <TextArea
          placeholder="Problem Description"
          size="large"
          value={addProblemModal?.description }
          onChange={(e) =>
            setAddProblemModal({
              ...addProblemModal,
              description: e.target.value,
            })
          }
          style={{ height: "150px" }}
        />
      </Form.Item>
    </div>,
   
    <div
      {...getRootProps()}
      className="flex flex-col h-[174px] items-center justify-center border-2 border-dashed border-blue-600 rounded-lg p-4 hover:bg-blue-50 transition duration-200 ease-in-out"
    >
      {uploadedFile ? (
        <div className="flex items-center gap-2 mt-2 text-blue-600">
          {uploadedFile.type === "application/pdf" ? (
            <FaFilePdf fontSize={40} className="text-blue-600" />
          ) : (
            <FaFileAlt fontSize={40} className="text-blue-600" />
          )}
          <div className="flex gap-4">
            <span className="text-sm font-medium">{uploadedFile.name}</span>
            <FaTimes
              size={20}
              color="red"
              className="cursor-pointer"
              onClick={() => removeFile()}
            />
          </div>
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center">
          <FaFileUpload
            fontSize={40}
            className="text-blue-600 mb-2 cursor-pointer"
          />
          <input {...getInputProps()} className="hidden" />
          <span className="text-blue-600 text-sm">
            {error ? (
              <span className="text-red-500">{error}</span>
            ) : (
              " Drag & drop your files here or click to upload "
            )}
          </span>
        </div>
      )}
    </div>,
  ];

  return (
    <Modal
      centered
      open={addProblemModal.open}
      onCancel={handleCancel}
      onOk={handleSave}
      okText="Save"
      confirmLoading={addProblemLoading}
      width={900}
      bodyStyle={{ minHeight: 500 }}
      title="Add Problem"
    >
      <div className="flex flex-col">
        <Form layout="vertical">
          <div className="flex flex-col md:flex-row gap-4">
            {/* Select Promptlet */}
            <div className="md:w-[70%] w-full">
              <Form.Item
                label="Library"
                validateStatus={clientErrors?.library ? "error" : ""}
                help={clientErrors?.library}
                className="text-md font-bold"
              >
                <Select
                  size="large"
                  placeholder="Choose Library"
                  value={addProblemModal?.library || undefined}
                  onChange={(value) => handlePromptletSelection(value)}
                >
                  <OptGroup label="My Promptlets">
                    {stepsData?.myPromptlets?.map((item) => (
                      <Option key={item.value} value={item.value}>
                        <div className="flex justify-between px-2">
                          <span className="truncate">{item.label}</span>
                          <span className="text-[#8c8c8c] italic pl-2">
                            {item.author}
                          </span>
                        </div>
                      </Option>
                    ))}
                  </OptGroup>
                  <OptGroup label="Shared Promptlets">
                    {stepsData?.communityPromptlets?.map((item) => (
                      <Option key={item.value} value={item.value}>
                        <div className="flex justify-between px-2">
                          <span className="truncate">{item.label}</span>
                          <span className="text-[#8c8c8c] italic pl-2">
                            {item.author}
                          </span>
                        </div>
                      </Option>
                    ))}
                  </OptGroup>
                </Select>
              </Form.Item>
            </div>
            {/* Select Language */}
            <div className="md:w-[28%] w-full">
              <Form.Item label="Language" className="text-md font-bold">
                <Select
                  size="large"
                  placeholder="Select Language"
                  value={addProblemModal?.language || undefined}
                  onChange={(value) =>
                    setAddProblemModal({ ...addProblemModal, language: value })
                  }
                >
                  {supportedLanguages?.map((language) => (
                    <Option key={language} value={language}>
                      {language}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>
          <Form.Item
            label="Problem Title"
            validateStatus={clientErrors?.title ? "error" : ""}
            help={clientErrors?.title}
            className="text-md font-bold"
          >
            <Input
              placeholder="Problem Title"
              size="large"
              value={addProblemModal?.title}
              onChange={(e) =>
                setAddProblemModal({
                  ...addProblemModal,
                  title: e.target.value,
                })
              }
            />
          </Form.Item>
          <Tabs
            type="card"
            items={tabs.map((tabName, index) => {
              const id = String(index + 1);
              return {
                label: tabName,
                key: id,
                children: tabChildren[index],
              };
            })}
          />
        </Form>
      </div>
    </Modal>
  );
};

export default AddProblemModal;
