import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../../Config";
import axios from "axios";
import { toastError, toastSuccess } from "../../../utils/toast";

const initialState = {
  isLoading: false,
  isError: null,
  errors: [],
  isSuccess: false,
  message: "",
  step: {},
};

export const updatePromptletStep = createAsyncThunk(
  "Library/Update-Library",
  async ({ promptletId, stepId, step, onSuccess }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${BASE_URL}/api/user/library/${promptletId}/steps/${stepId}`,
        step
      );
      toastSuccess(response?.data?.data?.message);
      onSuccess && onSuccess();
      return response?.data;
    } catch (error) {
      toastError("Step Not Updated");
      return rejectWithValue(error?.response?.data);
    }
  }
);

const updatePromptletStepSlice = createSlice({
  name: "Update promptlet",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updatePromptletStep.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(updatePromptletStep.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.errors = [];
      state.isSuccess = action?.payload?.data?.isSuccess;
      state.message = action?.payload?.data?.message;
      state.step = action?.payload?.data?.data;
    });
    builder.addCase(updatePromptletStep.rejected, (state, action) => {
      state.isLoading = false;
      state.errors = action.payload?.data?.errors || [];
      state.isError = true;
    });
  },
});

export const { resetPromptlet, deletePromptletData } =
  updatePromptletStepSlice?.actions;

export default updatePromptletStepSlice?.reducer;
